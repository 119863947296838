<template>
  <div class="editUserWrap">
    <el-form
      :model="menuForm"
      status-icon
      ref="menuForm"
      label-width="100px"
      class="menuForm"
      :rules="menuFormRules"
    >
      <el-form-item
        v-for="f in eidtableFormItems"
        :key="f.prop"
        :label="f.label"
        :prop="f.prop"
      >
        <el-switch v-if="f.prop == 'status'" v-model="menuForm[f.prop]" />
        <el-cascader
          v-model="menuForm[f.prop]"
          :options="menusTreeData"
          clearable
          :props="{
            label: 'menu_name',
            children: 'children',
            value: 'id',
            checkStrictly: true,
          }"
          placeholder="请选择上传菜单"
          v-else-if="f.prop == 'parent_ids'"
        ></el-cascader>
        <el-input
          v-else
          v-model.number="menuForm[f.prop]"
          :placeholder="f.placeholder"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm()">提交</el-button>
        <el-button @click="$router.push('/menus')">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuForm: {},
      eidtableFormItems: [
        {
          prop: "menu_name",
          label: "菜单名称",
          placeholder: "请输入菜单名称",
        },
        {
          prop: "parent_ids",
          label: "上级名称",
        },
        {
          prop: "url",
          label: "地址",
          placeholder: "请输入地址",
        },
        {
          prop: "order_num",
          label: "菜单顺序",
          placeholder: "请输入菜单顺序",
        },
        {
          prop: "status",
          label: "状态",
        },
        {
          prop: "remark",
          label: "备注",
          placeholder: "请输入备注",
        },
      ],
      menuFormRules: {
        menu_name: [
          { required: true, message: `请输入菜单名称`, trigger: "blur" },
        ],
        parent_ids: [
          { required: true, message: "请选择上级菜单", trigger: "blur" },
        ],
        order_num: [
          { required: true, message: `请输入菜单顺序`, trigger: "blur" },
        ],
        url: [{ required: true, message: `请输入地址`, trigger: "blur" }],
      },
      menusTreeData: [],
    };
  },
  mounted() {
    this.getAllMenusTreeData();
    this.getMenuInfo();
  },
  methods: {
    getAllMenusTreeData() {
      this.$request.getAllMenusTreeData({}).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.menusTreeData = [res.data];
      });
    },
    getMenuInfo() {
      const { id } = this.$route.query;
      if (!id) return;
      this.$request.getMenuInfo({ id }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        const { ancestors, menu_name, url, status, remark, order_num } =
          res?.data || {};
        this.menuForm = {
          parent_ids: ancestors.split(","),
          menu_name,
          url,
          order_num,
          status: JSON.parse(status),
          remark,
        };
      });
    },

    submitForm() {
      this.$refs.menuForm.validate((valid) => {
        if (valid) {
          this.$request
            .menuOperation({
              ...this.menuForm,
              status: JSON.stringify(this.menuForm.status),
              id: this.$route.query.id,
            })
            .then((res) => {
              if (res.status != "success") {
                this.$message.error(res.msg);
                return;
              }
              this.$router.push("/menus");
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang='scss'>
.menuForm {
  background-color: #fff;
  padding: 24px 30% 24px 24px;
  .el-cascader--small {
    width: 100%;
  }
  .el-form-item__label {
    width: 30% !important;
  }
  .el-form-item__content {
    margin-left: 30% !important;
    .el-select {
      display: block;
    }
  }
}
</style>